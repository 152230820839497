<template>
    <div>
        <img src="../assets/Qr.jpg"  style="height: 400px;width: auto;margin:auto;">
        <div style="font-size: large;"> V：hdgryddrryysu</div>
       
    </div>


</template>
<script>

export default {
    data: function () {
        return {

        };
    },

    methods: {

    }
}
</script>
  
<style >
</style>
