<template>
    <div>
        <span>一、本网站包含画室、画廊、工具箱、消息、我的 共五个模块。提供 画室模块里的文生图、风格转绘、艺术二维码、光影字功能，以及工具箱模块里的Prompt提取器 功能。AI的潜力巨大，让人震惊兴奋，其能实现的功能远不只这些，后续抽空更新新功能。<br> <br>

二、关于文生图参数：<br>
大模型：大模型分为通用大模型和精修大模型。通用大模型可生成任意风格，以及任何物体的图片，但质量也许不是很高。精修大模型指在通用大模型基础上对某一方面进行着重训练，使精修大模型擅长被着重训练的领域，而其他领域则会表现得比较差劲。如写实|Majic 擅长生成真实女性图片，生成的二次元或者其他风格图片质量较拉跨。<br> <br>

宽高：宽度和高度参数的单位为px。图片越大，生成所需时间越多。<br> <br>

描述词：正面描述词和负面描述词需要用英文，建议使用翻译工具，如有道字典。描述词可自然语言，也可单词标签。描述词用英文半角逗号隔开。每一个提示词的默认权重为1，可通过添加括号的方式修改权重，如(blue sky:1.5)，权重建议不超过2，否则容易导致图片崩坏。自然语言形如：A lovely girl  is dancing gracefully in the moonlight, She has long black hair and a charming smile; 单词标签形如：1girl, dancing, （white moonlight：1.2）, at night, black hair, charming smile. <br> <br>

三、关于“剪刀“：<br>
凌晨12点刷新剪刀数量，为200。不同功能所需显卡算力不同，据此制定“剪刀“消耗规则：
Prompt提取器:1剪刀/次  文生图：3剪刀/次    艺术二维码：15剪刀/次
风格转绘：15   光影字：15 <br> <br>

四、关于“画廊“模块：<br>
这是一个用户分享图片的模块，美好有趣的东西就该分享出来。这儿可以获取展示图片的生成参数以生成类似图片。出现重叠是正常现象，刷新网页即可。为减少网站流量负担，以及加快图片加载速度提高用户体验，画廊里的图片都经过了一定比例的质量压缩，会导致清晰度低于作者分享的原图。<br> <br>

五、关于“艺术二维码“功能模块：<br>
该功能模块可以细分为两个小功能：<br>
功能一：将文本/网址 转化为艺术二维码。直接在URL/Text输入框里输入要转化的文本或网址即可，填入描述词或者点击筛子随机生成，最后点击“立即生成“按钮即可。
功能二：美化现有二维码。如美化微信明信片二维码。方法：点击 URL/Text输入框 右上角的 “Scan“ 按钮，选择需要美化的二维码，稍等片刻，该二维码会被自动转化为网址填入URL/Text 输入框，接着提示词然后点击”立即生成“按钮即可。<br>
悲伤的是，最近微信和qq更新，无法识别包含中文文字的二维码，所以使用功能一时想让生成的二维码能被微信or qq识别，请输入英文。

</span>


    </div>
</template>

<script>
export default {
    data: function () {
        return {
        };
    },
    methods: {
    }
}

</script>

<style>

</style>
