<template>
  <div >


    <RouterView></RouterView>
  </div>
</template>

<script>
import {  RouterView } from 'vue-router';

// import ElementUi from './views/ElementUi.vue';

export default{
    data: function () {
        return {
          
          
        };
    },
    methods: {},
    components: { RouterView }
}
</script>

<style>

</style>
